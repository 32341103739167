import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import NavBar from "./Components/NavBar";
import "./index.css";
import MobileNavBar from "./Components/MobileNavBar";
import Candidates from "./Components/Candidates";
import Testimonials from "./Components/Testimonials";
import Footer from "./Components/Footer";
import { Helmet } from "react-helmet";
import Home from "./Components/Home";
import {
  RouterProvider,
  createBrowserRouter,
  useLocation,
  matchPath
} from "react-router-dom";
import { Outlet } from "react-router-dom";
import OurServices from "./Components/OurServices";
import Organization from "./Components/Organization";
import ContactUs from "./Components/ContactUs";
import OurJourney from "./Components/OurJourney";
import NurtureTalent from "./Components/NurtureTalent";
import HireTalent from "./Components/HireTalent";
import CurrOpenings from "./Components/Current_Openings/CurrOpenings";
import UploadJob from "./Components/UploadJob";
import AdminLogin from "./Components/AdminLogin";
import { Provider } from "react-redux"
import { store } from "./store";
import AdminPanel from "./Components/AdminPanel";
import ChangePassword from "./Components/ChangePassword";
import SingleJob from "./Components/SingleJob";
// import Top from "./Components/Top";
const root = ReactDOM.createRoot(document.getElementById("root"));

const Applayout = () => {
  const location = useLocation();
  const [showFooter, setShowFooter] = useState(true);
  const [showNav, setShowNav] = useState(true);

  useEffect(() => {
    // Hide footer on contact page
    if (location.pathname === "/contact" || location.pathname === "/user_name_55300" || location.pathname === "/user-name-panel" || location.pathname.includes("/jobs/")) {
      setShowFooter(false);
    } else {
      setShowFooter(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    // Hide nav on contact page
    if (location.pathname === "/user_name_55300" || location.pathname === "/user-name-panel" || location.pathname === "/forgotpassword") {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
  }, [location.pathname]);

  return (
    <div className="app">
      <Helmet>
        <meta
          name="description"
          content="Your progress is our priority. Explore career services for professionals and customized hiring, training, and staffing solutions for organizations. Unlock your potential with us. "
        />
        <meta
          name="keywords"
          content="career services, professional growth, hiring solutions, training solutions, staffing solutions, talent development, progress"
        />
      </Helmet>
      {showNav && <NavBar />}
      {showNav && <MobileNavBar />}
      <Outlet />

      {showFooter && <Footer />}
      {/* <Top /> */}
    </div>
  );
};

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Applayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/professionals",
        element: <Candidates />,
      },
      {
        path: "/testimonials",
        element: <Testimonials />,
      },
      {
        path: "/ourservices",
        element: <OurServices />,
      },
      {
        path: "/organizations",
        element: <Organization />,
      },
      {
        path: "/why-us",
        element: <OurJourney />,
      },
      {
        path: "/contact",
        element: <ContactUs />,
      },

      {
        path: "/nurturetalent",
        element: <NurtureTalent />,
      },
      {
        path: "/hire-talent",
        element: <HireTalent />,
      },
      {
        path: "/currentopenings",
        element: <CurrOpenings />,
      },
      {
        path: "/editJob",
        state: { props: {} },
        element: <UploadJob />,
      },
      {
        path: "/user_name_55300",
        element: <AdminLogin/>
      },
      {
        path: "/user-name-panel",
        element: <AdminPanel/>
      },
      {
        path: "/forgotpassword",
        element: <ChangePassword/>
      },
      {
        path: "/jobs/:title/:location/:id",
        element: <SingleJob/>,
        state: { props: {} },
      },
      {
        path: "*",
        element: <ContactUs />,
      }
    ],
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
    <RouterProvider router={appRouter} />
    </Provider>
  </React.StrictMode>
);
