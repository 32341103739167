import React from "react";
import pragati from "../images/Logo.jpg";
import bits from "../images/BITS_logo.gif";
import coep from "../images/coep logo.png";
import pentair from "../images/Pentair_Logo_Color_RGB.png";
import sanjeev from "../images/Sanjeev.jpg";
const OurJourney = () => {
  return (
    <div className="our_journey_wrapper">
      <div className="header">
        <h1>About Us</h1>
        <div>
          <div className="main_heading">Uniting Engineering & HR</div>
        </div>
      </div>
      <div className="upper_part_wrapper">
        <div className="our_journey_upper_part">
          <div className="image">
            <a
              href="https://www.linkedin.com/in/sanjeev-nichani-684358a/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={sanjeev} alt="" />
              <div>Sanjeev Nichani</div>
            </a>
          </div>
          <div className="text text_box2">
            <p>
              From 2001 to 2022, Sanjeev has worked in corporate roles across
              functions, geographies and organizations ranging from a 11 people
              start-up to a 160,000 employee-strong fortune 500 MNC.
            </p>
            <p>
              In 2012, one of Sanjeev’s mentor-managers asked him to take on HR
              roles and responsibilities as a ‘growth assignment’.
            </p>
            <p>
              This marked the early beginning of an idea– combining engineering
              and HR.
            </p>
            <p>
              It took 10 years of gestation period for this early idea to fully
              mature.
            </p>
            <p>
              In 2022, Pragati People was founded on one simple premise:{" "}
              <span className="main_heading">“we rise by lifting others”.</span>
            </p>
          </div>
          {/* <span className="right_conatiner_arrow image_left_arrow"></span> */}
        </div>
      </div>
      <h1
        className="sanjeev_career main_heading"
        style={{ textAlign: "center" }}
      >
        Here is Sanjeev’s career trajectory over the last 2 decades.
      </h1>
      <div className="timeline">
        <div className="conatiner left_container">
          <img
            src="https://static.wixstatic.com/media/673276_d704f3cf998e4e80adf1aee4fb753f6a~mv2.png/v1/fill/w_196,h_80,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/tejas_logo.png"
            alt="logo"
          />

          <div className="text_box">
            {/* <h2>University of South Florida</h2> */}
            {/* <small>2001</small> */}
            <p>Started first job as NPD Engineer at Tejas RE @ Houston</p>
            {/* <p> Started first job at Tejas RE @ Houston</p> */}
            <span className="left_conatiner_arrow"></span>
          </div>
          <h1 className="date_left_container">2001</h1>
        </div>

        <div className="conatiner right_container">
          <img
            src="https://www.ge.com/themes/custom/ge_com_unified/logo.svg"
            alt="logo"
          />
          <div className="text_box">
            {/* <h2>General Electric</h2> */}
            {/* <small>2004</small> */}
            <p>Returned to India</p>
            <p>
              Learned the value of rigor, data and best practices, while working
              at GE
            </p>
            <span className="right_conatiner_arrow"></span>
          </div>
          <h1 className="date_right_container">2004</h1>
        </div>
        <div className="conatiner left_container">
          <img
            src="https://cdn.cookielaw.org/logos/a5cf333d-9e41-47cb-bf13-20ab1a801997/acd4cad2-1049-4b5d-8708-ea939311930e/24b7e23b-0e28-42fe-bbc3-17c68372ff9a/cummins_logo_white.gif"
            alt="logo"
          />
          <div className="text_box">
            {/* <h2>Cummins</h2> */}
            {/* <small>2006</small> */}
            <p>
              Delivered first formal training class, while working as FEA
              engineer at Cummins.
            </p>
            <span className="left_conatiner_arrow"></span>
          </div>
          <h1 className="date_left_container">2006</h1>
        </div>
        <div className="conatiner right_container">
          <img
            src="https://www.slb.com/-/media/images/logo/slb_logo_positive_rgb_tm.ashx"
            alt="logo"
          />
          <div className="text_box">
            {/* <h2>SLB</h2> */}
            {/* <small>2007-2020</small> */}
            <p>
              Started at Schlumberger (SLB), Fortune-500 MNC Rotated across
              roles in engineering and HR (India and Singapore)
            </p>
            <span className="right_conatiner_arrow"></span>
          </div>
          <h1 className="date_right_container">2007</h1>
        </div>
        <div className="conatiner left_container">
          <img src={coep} alt="logo" />
          <div className="text_box">
            {/* <h2>College of Engineering, Pune</h2> */}
            {/* <small>2017-2019 </small> */}
            <p>Conducted career workshops for COEP’s engineering graduates</p>

            <span className="left_conatiner_arrow"></span>
          </div>
          <h1 className="date_left_container">2017</h1>
        </div>
        <div className="conatiner right_container">
          <img src={pentair} alt="logo" />
          <div className="text_box">
            {/* <h2>Pentair</h2> */}
            {/* <small>2020-2022</small> */}
            <p>Led Innovation and Product development teams</p>
            <p>Developed a new internal knowledge-base</p>
            <span className="right_conatiner_arrow"></span>
          </div>
          <h1 className="date_right_container">2020</h1>
        </div>
        <div className="conatiner left_container">
          <img
            src={pragati}
            alt="logo"
            style={{
              border: "2px solid black",
              borderRadius: "1rem",
              height: "4rem",
              top: "-3rem",
            }}
          />
          <div className="text_box">
            {/* <h2>BITS Pilani</h2> */}
            {/* <small>2022</small> */}
            <p>Started Pragati People</p>

            <span className="left_conatiner_arrow"></span>
          </div>
          <h1 className="date_left_container">2022</h1>
        </div>
        <div className="conatiner right_container">
          <img src={bits} alt="logo" />
          <div className="text_box">
            {/* <h2>Pragati People</h2> */}
            {/* <small>2024</small> */}
            <p>Taught semester-long MTech course as adjunct faculty of BITS</p>
            <span className="right_conatiner_arrow"></span>
          </div>
          <h1 className="date_right_container">2023</h1>
        </div>
        <div className="conatiner left_container">
          {/* <img
            src={pragati}
            alt="logo"
          /> */}
          <div className="text_box">
            {/* <h2>BITS Pilani</h2> */}
            {/* <small>2022</small> */}
            <p>Journey continues…...</p>

            <span className="left_conatiner_arrow"></span>
          </div>
          <h1 className="date_left_container">2024</h1>
        </div>
      </div>
      <div className="home_data">
        <div className="message">
          Its been an exciting journey, fueled by support and guidance from a
          large group of managers, mentors and well-wishers.
        </div>
        <div className="message">
          We wish to extend this same support and guidance to our customers
        </div>
      </div>
    </div>
  );
};

export default OurJourney;
