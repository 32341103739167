import React from "react";
import engineering from "../images/Engineering.jpg";
import boat from "../images/boats.jpeg";
import diversity from "../images/Diversity.jpg";
import lock from "../images/lock.jpg";
import globe from "../images/Globe.jpg";
import checklist from "../images/checklist.jpg";
import cd1 from "../images/cd1.jpg";
import cd2 from "../images/cd2.jpg";
import hm1 from "../images/hm1.jpg";
import hm2 from "../images/hm2.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import right from "../images/right.jpg";
import left from "../images/left.jpg";
import performance from "../images/pragati_precision-3.jpg";
const HireTalent = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scrolling smooth
    });
  };
  return (
    <div className="hire_wrapper">
      <Helmet>
        <meta
          name="description"
          content="Accelerate your technical hiring with Pragati People. We leverage our engineering expertise to bring you vetted candidates, saving you weeks of screening time. Partner with us to build your team. "
        />
        <meta
          name="keywords"
          content="technical hiring, recruitment solution, staffing solution, talent acquisitio, find talen, efficient hirin, technical recruitment"
        />
      </Helmet>
      <div className="header">
        <h1>Hire Talent</h1>
        <div className="main_heading">
          <span className="first_alpha">A</span>ccelerate your Hiring with
          Prevetted profiles
        </div>
        <div>
          <span className="first_alpha">T</span>alent sourcing is Pragati’s
          flagship service.
        </div>
        <div>
          Our global engineering expertise gives us a competitive advantage in
          recruiting top talent.
        </div>
      </div>
      <div className="hiring_speciality">
        <h1
          className="talent_heading main_heading"
          style={{ textAlign: "center", fontSize: "2rem", marginTop: "2rem" }}
        >
          Our Hiring Specialties
        </h1>

        <div className="border">
          <div className="our_services home_services gap">
            <div className="who cursor">
              <img src={engineering} alt="" />
              <div className="upper_layer">
                <h2>Engineering</h2>
              </div>
            </div>
            <div className="who cursor">
              <img src={diversity} alt="" />
              <div className="upper_layer">
                <h2>Diversity</h2>
              </div>
            </div>
            <div className="what cursor">
              <img src={boat} alt="" />
              <div className="upper_layer">
                <h2>Leadership</h2>
              </div>
            </div>
          </div>
          <div className="text">
            In addition to the specialties listed, we've helped clients hire for
            and fill a broad array of positions.
          </div>
        </div>

        <div className="best_innings border">
          <h1
            className="talent_heading main_heading"
            style={{ textAlign: "center", fontSize: "2rem", marginTop: "2rem" }}
          >
            Our Best Innings
          </h1>
          <div className="performance">
            <div className="text">
              Our journey is most{" "}
              <span className="career_pragati">fulfilling</span> when we match
              skilled engineers with exceptional roles. <br />
              Our greatest achievement to date is filling four mechanical
              engineer positions by presenting seven diversity profiles within
              45 days.
            </div>

            <img src={performance} alt="" />
            <div className="text">
              We're striving to surpass our own records, while staying mindful
              that as a young start-up, we have much to learn and prove.
            </div>
          </div>
        </div>
        <div className="pragati_hiring">
          <h1
            className="talent_heading main_heading"
            style={{ textAlign: "center", fontSize: "2rem", marginTop: "2rem" }}
          >
            How does ‘Pragati Hiring’ work?
          </h1>
          <div className="row left">
            <img src={globe} alt="" />
            <div className="text">
              We look <span className="career_pragati">far</span> , we look{" "}
              <span className="career_pragati">wide</span>, <br /> we go{" "}
              <span className="career_pragati">deep</span>, we go{" "}
              <span className="career_pragati">high...</span> <br />
              We scan for candidates in all 8 directions.
            </div>
          </div>
          <div className="row">
            <div className="text">
              After identifying candidates, we rigorously{" "}
              <span className="career_pragati">screen</span> them to ensure they
              align with both the technical requirements and cultural values of
              your role and organization
            </div>
            <img src={checklist} alt="" />
          </div>
          <div className="row">
            <div className="target_container">
              <p>Conventional Hiring</p>
              <img src={left} alt="" />
            </div>
            <div className="text">
              Traditional hiring methods often waste significant time and effort
              sifting through a large volume of incoming resumes. <br /> With
              Pragati-Hiring, <br /> Every{" "}
              <span className="career_pragati">single</span> profile is{" "}
              <span className="career_pragati">prevetted</span>.
            </div>
            <div className="target_container">
              <p>Pragati Hiring</p>
              <img src={right} alt="" />
            </div>
          </div>
          <div className="row">
            <div className="text">
              Your teams only have to process our prefiltered small stack of
              relevant profiles. This means, you only have to turn the knob a
              few times before you hear a{" "}
              <span className="career_pragati">“click”</span>.
            </div>
            <img src={lock} alt="" />
          </div>

          <h1
            className="talent_heading main_heading"
            style={{ textAlign: "center", fontSize: "2rem", marginTop: "2rem" }}
          >
            Does it really work?
          </h1>
          <div className="text">
            Here is real life feedback from{" "}
            <span className="career_pragati">Hiring Managers</span> .
          </div>
          <div className="our_services home_services">
            <div className="noscale">
              <img src={hm1} alt="" className="test" />
            </div>
            <div className="noscale">
              <img src={hm2} alt="" className="test" />
            </div>
          </div>
          <div className="text">
            Here is real life feedback from{" "}
            <span className="career_pragati">Candidates</span>.
          </div>
          <div className="our_services home_services">
            <div className="noscale">
              <img src={cd1} alt="" className="test" />
            </div>
            <div className="noscale">
              <img src={cd2} alt="" className="test" />
            </div>
          </div>
        </div>
      </div>
      <div className="home_data">
        <div className="message">
          Due to employment or contractual obligations, many of our customers
          have requested anonymity.
        </div>
        <div className="message">
          Here are some{" "}
          <Link to={"/testimonials"} onClick={scrollToTop}>
            customer-testimonials
          </Link>{" "}
          with their full names and LinkedIn profiles.
        </div>
      </div>
    </div>
  );
};

export default HireTalent;
