import { IoMdMail } from "react-icons/io";
import sanjeev from "../images/Sanjeev.jpg";
const ContactUs = () => {
  return (
    <div className="contact_wrapper">
      <div className="header">
        <h1> Connect to Collaborate</h1>
        <div className="main_heading"></div>
        <div>
          <span className="first_alpha">T</span>ell us how we can work together.
        </div>
      </div>
      <div className="contact_data">
        <div className="card-wrapper">
          <a
            href="https://www.linkedin.com/in/sanjeev-nichani-684358a/"
            target="_blank"
            rel="noreferrer"
            style={{textDecoration: "none",
              color: "black"}}
          >
            <div className="contact_card">
              <div className="image_wrapper">
              <img src={sanjeev} alt="Linkedin" />
              <div>Sanjeev Nichani</div>
              </div>

              <h2>Reach out to a partner</h2>
            </div>
          </a>
          <a
            href="mailto:open.pragati@gmail.com"
            target="_blank"
            rel="noreferrer"
            style={{textDecoration: "none",
              color: "black"}}
          >
            <div className="contact_card">
              <IoMdMail />

              <h2>Email Us</h2>
            </div>
          </a>
        </div>
        <div className="seperater">Prefer filling a form ?</div>
        <form action="https://formspree.io/f/mnqegkkp" method="POST">
          <div className="input-wrap">
          <input
              type="text"
              name="Name"
              placeholder="Your Name..."
             
              required
              autocomplete="off"
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email..."
              required
              autocomplete="off"
             
            />
          </div>
          <div className="input-wrap-2">
            <input
              type="linkedin"
              name="linkedin"
              placeholder="Your LinkedIn Profile..."
              required
              autocomplete="off"
             
            />
            
            <input
              name="subject"
              type="text"
              placeholder="Subject..."
             
              required
              autocomplete="off"
            />
            <textarea
              name="message"
              id=""
              cols="30"
              rows="10"
              placeholder="Your Message..."
              
              required
              autocomplete="off"
              minlength="5"
            ></textarea>
          </div>
          <div className="btn-wrapper">
            <button className="btn_primary" type="submit">
              Send Message
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ContactUs;
