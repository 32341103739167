import home1 from "../images/HomeFixed1.jpg";
import home2 from "../images/HomeFixed2.jpg";
import about2 from "../images/about2.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const Home = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="home_wrapper">
      <Helmet>
        <meta
          name="description"
          content="Your progress is our priority. Explore career services for professionals and customized hiring, training, and staffing solutions for organizations. Unlock your potential with us. "
        />
        <meta
          name="keywords"
          content="career services, professional growth, hiring solutions, training solutions, staffing solutions, talent development, progress"
        />
      </Helmet>
      <div className="img_wrapper">
        <h1 className="propelling">Propelling</h1>
        <h1 className="progress">progress</h1>
      </div>
      <div className="home_data">
        <div className="career-pragati">
          A company built with a single point agenda - “Your Progress”
        </div>
        <div>Progress is a more than a word, it’s a journey.</div>
        <div>
          Using our engineering expertise, we unite professionals and
          organizations from around the world to design unique journeys that
          foster mutual progress.
        </div>
      </div>
      <div className="our_services home_services">
        <div className="what special" onClick={scrollToTop}>
          <Link to={"/ourservices"}>
            <img src={home1} alt="" />
            <div className="upper_layer">
              <h1>Our Services</h1>
            </div>
          </Link>
        </div>
        <div className="what special" onClick={scrollToTop}>
          <Link to={"/testimonials"}>
            <img src={about2} alt="" />
            <div className="upper_layer">
              <h1>Testimonials</h1>
            </div>
          </Link>
        </div>
      </div>

      <div className="home_data">
        <div>
          If you’ve got a{" "}
          <span className="career-pragati phone_styling">north star</span>, we
          bring the{" "}
          <span className="career-pragati phone_styling">flight plan</span>.
        </div>
        <div>
          If you’ve got a{" "}
          <span className="career-pragati phone_styling">flight plan</span>, we
          bring <span className="career-pragati phone_styling">the fuel</span>.
        </div>
      </div>
    </div>
  );
};
export default Home;
