import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { BsCalendar2CheckFill } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa6";
import { BsTwitterX } from "react-icons/bs";
import { IoLogoLinkedin } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import { TbCopy } from "react-icons/tb";
import { Toaster, toast } from "react-hot-toast";
import { FaArrowLeftLong } from "react-icons/fa6";
import { serverUrl } from "./BackendUrl";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
const SingleJob = (props) => {
  const navigate = useNavigate();
  const route = useLocation();
  const loggedin = useSelector((state) => state.user.loggedin);

  useEffect(() => {
    fetchJob();
  }, []);

  const [data, setData] = useState({});
  const jobData = useParams();
  const [data_what, setData_what] = useState([]);
  const [data_who, setData_who] = useState([]);
  const [data_tools, setData_tools] = useState([]);
  const [data_employer, setData_employer] = useState([]);
  const [data_role, setData_role] = useState([]);
  const [showSocials, setShowSocials] = useState(false);
  const fetchJob = async () => {
    try {
      const jid = jobData.id;
      const response = await fetch(`${serverUrl}/job/get-single-job/${jid}`);
      if (response.status === 200) {
        const res = await response.json();
        setData(res);
        // console.log(data);
        // console.log(data.archived);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    setData_what(data.what?.split(".").filter((part) => part.trim() !== ""));
    setData_who(data.who?.split(".").filter((part) => part.trim() !== ""));
    setData_tools(data.tools?.split(".").filter((part) => part.trim() !== ""));
    setData_employer(
      data.employer?.split(".").filter((part) => part.trim() !== "")
    );
    setData_role(
      data.roleType?.split(".").filter((part) => part.trim() !== "")
    );

    // console.log(data.priority);
  }, [data]);

  const postUrl = window.location.href;
  const copyUrl = async () => {
    try {
      const url = window.location.href;
      await navigator.clipboard.writeText(url);
      toast("URL copied to clipboard");
    } catch (error) {
      console.error("Error copying URL:", error);
    }
  };

  return (
    <>
      {!data.archived || loggedin ? 
      // Live Jobs
      <>
      <p
        className="back_btn"
        role="button"
        onClick={() => navigate("/currentopenings")}
      >
        <FaArrowLeftLong />
        <span>Back to "All Jobs"</span>
      </p>
      {loggedin ? (
        <p
          className="back_btn"
          role="button"
          onClick={() => navigate("/user-name-panel")}
        >
          <FaArrowLeftLong />
          <span>Back to "Admin"</span>
        </p>
      ) : null}
      <div className="single_job">
        <div className="title_box">
          <div className="title_data">
            <h1 className="text-3xl">{data.title}</h1>
            <div className="title_subdata">
              <div className="title_subdata1">
                <FaLocationDot /> <p>{data.location}</p>
              </div>
              <div className="title_subdata2">
                <BsCalendar2CheckFill />{" "}
                <p>
                  {data.minExp} - {data.maxExp} Years
                </p>
              </div>
            </div>
          </div>
          <a
            href="https://forms.gle/EmiUGbfzZhCFeFTt6"
            target="_blank"
            rel="noreferrer"
            className="apply_btn"
          >
            <button className="apply_btn bg-white">Apply</button>
          </a>
        </div>
        <div className="details_box">
          <div className="details details1">
            <p className="details_title">
              Job ID:
              <span> {data.jobid}</span>
            </p>
          </div>
          <div className="details details1">
            <p className="details_title">
              Category:
              <span> {data.discipline}</span>
            </p>
          </div>
          <div className="details details1">
            <p className="details_title">
              Industry:
              <span> {data.industry}</span>
            </p>
          </div>
          <div className="details details2">
            <p className="details_title">WHAT (the role involves):</p>
            <ul className="list-disc">
              {data_what?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div className="details details2">
            <p className="details_title">WHO (is an ideal candidate):</p>
            <ul className="list-disc">
              {data_who?.map((item, index) => (
                <li key={index}>{item.length > 2 && item}</li>
              ))}
            </ul>
          </div>
          {data.tools && (
            <div className="details details2">
              <p className="details_title">WHICH (Skills/Tools are needed):</p>
              <ul className="list-disc">
                {data_tools?.map((item, index) => (
                  <li key={index}>{item.length > 2 && item}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="details details1">
            <p className="details_title flex flex-col">
              WHERE (the role is based):
              <span> {data.location}</span>
            </p>
          </div>
          <div className="details details2">
            <p className="details_title">Employer:</p>
            <ul className="list-disc">
              {data_employer?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div className="details details2">
            <p className="details_title">Role Type:</p>
            <ul className="list-disc">
              {data_role?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div className="details_btn_wrapper">
            <div className="details_btn_wrapper2">
              <a
                href="https://forms.gle/EmiUGbfzZhCFeFTt6"
                target="_blank"
                rel="noreferrer"
              >
                <button className="details_btn">Apply</button>
              </a>
              <button
                className="details_share_btn"
                onClick={() => setShowSocials(!showSocials)}
              >
                Share
              </button>
            </div>
            <div className="details_btn_wrapper2">
              {showSocials && (
                <div className="share_wrapper">
                  <a
                    href={`https://www.facebook.com/sharer.php?u=${postUrl}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="details_icon_btn">
                      <FaFacebook className="details_icon" />
                    </button>
                  </a>
                  <a
                    href={`https://twitter.com/intent/tweet?url=${postUrl}&text=${data.title}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="details_icon_btn">
                      <BsTwitterX className="details_icon  twitter" />
                    </button>
                  </a>
                  <a
                    href={`http://www.linkedin.com/shareArticle?mini=true&url=${postUrl}&title=${data.title}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="details_icon_btn">
                      <IoLogoLinkedin className="details_icon" />
                    </button>
                  </a>
                  <a
                    href={`https://wa.me/?text=${postUrl}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="details_icon_btn">
                      <FaWhatsapp className="details_icon" />
                    </button>
                  </a>
                  <button
                    className="details_icon_btn"
                    onClick={() => copyUrl()}
                  >
                    <TbCopy className="details_icon" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <Toaster position="top-right" />
      </div>
      <p
        className="back_btn"
        role="button"
        onClick={() => navigate("/currentopenings")}
      >
        <FaArrowLeftLong />
        <span>Back to "All Jobs"</span>
      </p> 
      </> :

      // Closed Jobs
      <>
      <div className="header single_job_closed">
            <div className="total_jobs flex">  
              <div>
              This job is no longer available
                <br />
                Please check other{" "}
                <span className="career-pragati open_application">
                  <a
                    href="/currentopenings"
                  >
                    Current Openings here
                  </a>
                </span>
                .
              </div>
            </div>
          </div>
      </>
      }
    </>
  );
};

export default SingleJob;
