import { useState, useEffect } from "react";
import React from "react";
import OpeningsBox from "./Current_Openings/OpeningsBox";
import p2 from "../images/p2.jpg";
import { useSelector, useDispatch } from "react-redux";
import { userClick } from "../Slices/userSlice";
import { useNavigate } from "react-router-dom";
import UploadJob from "./UploadJob";
import { serverUrl } from "./BackendUrl.js";
import { LuCalendarCheck } from "react-icons/lu";
import { IoSearch } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { Country, State, City } from "country-state-city";
import CsvDownloadButton from "react-json-to-csv";
import { useRef } from "react";

const AdminPanel = (props) => {
  const [activeTab, setActiveTab] = useState("Live Jobs");
  const [archived, setArchived] = useState([]);
  const [fixedArchived, setFixedArchived] = useState([]);
  const [editType, setEditType] = useState("");
  const loggedin = useSelector((state) => state.user.loggedin);
  const navigate = useNavigate();
  const [currJobId, setCurrJobId] = useState(null);
  const [disciplines, setDisciplines] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [locations, setLocations] = useState([]);
  const dispatch = useDispatch();
  const [fixedData, setFixedData] = useState([]);
  const [data, setData] = useState([]);
  const [showIndex, setShowIndex] = useState(-1);
  const [showIndex2, setShowIndex2] = useState(-1);
  const [keyword, setKeyword] = useState("");
  const experienceArr = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];
  const [keywordOptions, setKeywordOptions] = useState([]);
  const [fixedKeywordOptions, setFixedKeywordOptions] = useState([]);
  const [exp, setExp] = useState("Experience");
  const [tempKeyword, setTempKeyword] = useState("");
  // const [locationArr, setLocationArr] = useState([]);
  const locationArr = City.getCitiesOfCountry("IN");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [fixedLocationOptions, setFixedLocationOptions] = useState([]);
  const [locationKeyword, setLocationKeyword] = useState("");
  const [locationBoxOpen, setLocationBoxOpen] = useState(false);
  const [keywordBoxOpen, setKeywordBoxOpen] = useState(false);
  const [experienceBox, setExperienceBox] = useState(false);
  const keywordsRef = useRef(null);
  const locationRef = useRef(null);
  const experienceRef = useRef(null);

  // SEARCH FUNCTIONALITY

  const filterLocationList = () => {
    // console.log("Running");
    let filteredList = [];
    // take only first word of the name of the city
    locationArr.forEach((item) => {
      filteredList.push(item.name.split(" ")[0]);
    });
  
    // remove duplicates
    filteredList = [...new Set(filteredList)];
    //  Remove East and West from the list
    filteredList = filteredList.filter((item) => item !== "East");
    filteredList = filteredList.filter((item) => item !== "West");
    filteredList = filteredList.filter((item) => item !== "North");
    filteredList = filteredList.filter((item) => item !== "South");
    filteredList = filteredList.filter((item) => item !== "Navi");
    filteredList.push("Navi Mumbai");
  
  
    // console.log(filteredList);
    setLocationOptions(filteredList); 
    // console.log(locationOptions)
    setFixedLocationOptions(filteredList);
  }
  
  const handleClickOutsideKeywords = (event) => {
    if (keywordsRef.current && !keywordsRef.current.contains(event.target)) {
      setKeywordBoxOpen(false);
    }
  };
  const handleClickOutsideLocation = (event) => {
    if (locationRef.current && !locationRef.current.contains(event.target)) {
      setLocationBoxOpen(false);
    }
  };
  const handleClickOutsideExperience = (event) => {
    if (experienceRef.current && !experienceRef.current.contains(event.target)) {
      setExperienceBox(false);
    }
  };
  
    const getkeywords = async () => {
      // console.log("Running keywords");
      try {
        // remove cors policy
        const response = await fetch(`${serverUrl}/job/get-keywords`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const res = await response.json();
        setFixedKeywordOptions(res);
        setKeywordOptions(res);
        // console.log(res);
      } catch (error) {
        console.log(error);
      }
    }
    const handleSearch2 = () => {
      let keywords = keyword.split(",");
      keywords = keywords.map((item) => item.trim());
      keywords = keywords.filter((item) => item !== "");
      let locations = locationKeyword.split(",");
      locations = locations.map((item)=> item.trim());
      locations = locations.filter((item) => item !== "");
      // console.log(keywords);  
      let filteredKeywords = [];
      let filteredExp = [];
      let filteredLocation = [];
      // console.log("keywords", keywords);
  
      // Keyword Search
      if(keywords.length === 0) {
        filteredKeywords = fixedData;
      }
      if (keywords.length > 0) {
        keywords.forEach((key) => {
          const filteredTitleData = fixedData.filter((item) => {
            return (
              item?.title?.toLowerCase().includes(key.toLowerCase()) ||
              item?.discipline?.toLowerCase().includes(key.toLowerCase()) ||
              item?.tools?.toLowerCase().includes(key.toLowerCase())
            );
          });
          // Exclude duplicates
          filteredKeywords = [...new Set([...filteredKeywords, ...filteredTitleData])];
        });
      } else {
        const filteredTitleData = fixedData.filter((item) => {
          return (
            item?.title?.toLowerCase().includes(keyword.toLowerCase()) ||
            item?.discipline?.toLowerCase().includes(keyword.toLowerCase()) ||
            item?.tools?.toLowerCase().includes(keyword.toLowerCase())
          );
        });
        filteredKeywords = [...filteredKeywords, ...filteredTitleData];
      }
  
      // Experience Search
      if(exp === "Experience"){
        filteredExp = fixedData;
      }
      if (Number(exp) >= 0) {
        filteredExp = fixedData.filter((item) => {
          return item?.minExp <= Number(exp) && item?.maxExp >= Number(exp);
        });
      } else {
        filteredExp = fixedData;
      }
  
      // Location Search
      if (locations.length > 0) {
        locations.forEach((loc) => {
          const filteredLoc = fixedData.filter((item) => {
            return item?.location.toLowerCase().includes(loc.toLowerCase());
          });
          // Exclude duplicates
          filteredLocation = [...new Set([...filteredLocation, ...filteredLoc])];
        });
      } else {
        filteredLocation = fixedData;
      }
  
      // Find intersection of all filters
      const filteredData = filteredKeywords.filter((item) => {
        return filteredExp.includes(item) && filteredLocation.includes(item);
      });
      // Ensure that all the jobs unique
      const uniqueFilteredData = [...new Set(filteredData)];
      return setData(uniqueFilteredData);
    };
  
    const reverseData = (arr) => {
      let finalArr = [];
      let priorArr = [];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i]?.priority === "True") {
          priorArr.push(arr[i]);
          // console.log("prior" ,priorArr)
        } else {
          finalArr.push(arr[i]);
          // console.log("final" ,finalArr)
        }
      }
      finalArr = finalArr.slice().reverse();
      let combinedData = [...priorArr, ...finalArr];
      return combinedData;
    };
  
    const keywordOptionsFunc = (word) => {
      if(word === "") {
        setKeywordOptions([])
      }
      const options = fixedKeywordOptions.filter((item) => {
        return item.toLowerCase().startsWith(word.toLowerCase());
      })
      setKeywordOptions(options);
    };
  
    useEffect(() => {
      getJobs();
      getkeywords();
      filterLocationList();
      document.addEventListener('click', handleClickOutsideKeywords);
      document.addEventListener('click', handleClickOutsideLocation);
      document.addEventListener('click', handleClickOutsideExperience);
    }, []);
    
    const handleLocationArray = (location) => {
      if(location === ""){
        setLocationOptions([]);
      }
      console.log(location);
      
      const options = fixedLocationOptions.filter((item) => {
        return item.toLowerCase().startsWith(location.toLowerCase());
      })
      setLocationOptions(options);
    };
  
    const removeLocation = (item) => {
      const newLocation = selectedLocation.filter((loc) => loc !== item);
      setSelectedLocation(newLocation);
      // console.log(selectedLocation);
      handleSearch2();
    };
  
    const setKeywordFunc = (item) => {
      const words = keyword.split(',').map(word => word.trim());
      const newKeyword = words.slice(0, words.length - 1).concat(item.trim()).join(', ');
      setKeyword(`${newKeyword},`);
    };
    const setLocationFunc = (item) => {
      const words = locationKeyword.split(',').map(word => word.trim());
      const newLocation = words.slice(0, words.length - 1).concat(item.trim()).join(', ');
      setLocationKeyword(`${newLocation},`);
    };
  

  // SEARCH FUNCTIONALITY END

  const logOut = () => {
    dispatch(userClick());
    navigate("/user_name_55300");
  };
  useEffect(() => {
    if (!loggedin) {
      navigate("/");
    }
    getJobs();
    getArchivedJobs();
    getLocations();
    getDiscipline();
    getIndustries();
  }, []);
  const getJobs = async () => {
    try {
      const response = await fetch(`${serverUrl}/job/get-jobs`);
      const res = await response.json();
      // console.log(res)
      setFixedData(reverseData(res));
      setData(reverseData(res.slice()));
      // getOptions();
    } catch (error) {
      console.log(error);
    }
  };

  const getArchivedJobs = async () => {
    try {
      const response = await fetch(`${serverUrl}/job/get-archived-jobs`);
      const res = await response.json();
      setArchived(reverseData(res));
      setFixedArchived(reverseData(res.slice()));
      // getOptions();
    } catch (error) {}
  };

  // const getOptions = () => {
  //   const completeData = [...fixedData, ...fixedArchived];
  //   const disciplines = [...new Set(completeData.map((item) => item.discipline))];
  //   const industries = [...new Set(completeData.map((item) => item.industry))];
  //   const locations = [...new Set(completeData.map((item) => item.location))];
  //   const newlocations = locations.filter((item) => item !== "Navi Mumbai.");
  //   setDisciplines(disciplines);
  //   setIndustries(industries);
  //   setLocations(newlocations);
  // }

  const getLocations = async () => {
    try {
      const response = await fetch(`${serverUrl}/job/get-location`);
      const res = await response.json();
      setLocations(res);   
    } catch (error) {
      window.alert(error);
    }
  }

  const getDiscipline = async () => {
    try {
      const response = await fetch(`${serverUrl}/job/get-discipline`);
      const res = await response.json();
      setDisciplines(res);   
    } catch (error) {
      window.alert(error);
    }
  }

  const getIndustries = async () => {
    try {
      const response = await fetch(`${serverUrl}/job/get-industry`);
      const res = await response.json();
      setIndustries(res);   
    } catch (error) {
      window.alert(error);
    }
  }

  return (
    <div className="adminpanel">
      <div className="admin_nav">
        <div className="admin_nav_sec">
          <button
            className={`options ${activeTab === "Live Jobs" ? "active" : ""}`}
            onClick={() => setActiveTab("Live Jobs")}
          >
            Live Jobs
          </button>
          <button
            className={`options ${
              activeTab === "Archived Jobs" ? "active" : ""
            }`}
            onClick={() => setActiveTab("Archived Jobs")}
          >
            Archived Jobs
          </button>
          <button
            className={`options ${activeTab === "Upload Job" ? "active" : ""}`}
            onClick={() => {
              setCurrJobId(null);
              setActiveTab("Upload Job");
            }}
          >
            Upload Job
          </button>
        </div>
        <div className="log_out options" onClick={() => logOut()}>
          Log Out
        </div>
      </div>

      {/* ---------------------------- Live Jobs -------------------------- */}
      {activeTab === "Live Jobs" && (
        <div className="curr_openings_wrapper" style={{ overflowX: "hidden" }}>
          <div className="flex justify-end w-full">
            <CsvDownloadButton
              className={`options active`}
              data={fixedData}
              filename="Live Jobs"
            />
          </div>
          <div className="curr_openings_body">
         {/* SEARCH FUNCTIONALITY START */}
            <div className="search flex flex-col">
              <div className="flex justify-between flex-col xl:flex-row w-full items-start">
                <div className="flex flex-col md:flex-row w-full xl:w-11/12 justify-between items-start gap-4">
                  {/* KEYWORDS SEARCH  */}
                  <div
                    ref={keywordsRef}
                    className="flex flex-col w-[95%] lg:w-1/3 relative"
                  >
                    <div className="keyword flex flex-col w-full items-center">
                      <input
                        value={keyword}
                        type="text"
                        onChange={(e) => {
                          const newKeyword = e.target.value;
                          if (newKeyword.length > 1) {
                            setKeywordBoxOpen(true);
                          } else if (newKeyword.length < 2) {
                            setKeywordBoxOpen(false);
                          }
                          keywordOptionsFunc(
                            newKeyword.split(",").pop().trim()
                          );
                          setKeyword(e.target.value);
                        }}
                        className="border-2 border-[#4D2A7C] w-full py-2 pr-9 pl-9 rounded-lg my-1 h-11 z-10"
                        placeholder="Enter Title or Skill"
                        onKeyDown={(event) =>
                          event.key === "Enter" &&
                          setKeyword(event.target.value)
                        }
                      />
                      <IoSearch className="cursor-pointer text-gray-600 z-20 absolute left-3 top-1/2 -translate-y-1/2" />
                      <MdClose
                        className="cursor-pointer text-gray-600 z-20 absolute right-3 top-1/2 -translate-y-1/2"
                        onClick={() => {
                          setKeyword("");
                          setKeywordBoxOpen(false);
                          setKeywordOptions(fixedKeywordOptions);
                          handleSearch2();
                          setTempKeyword("");
                          setKeywordBoxOpen(false);
                        }}
                      />
                    </div>
                    {keywordBoxOpen && (
                      <div className="border-2 border-[#4D2A7C] absolute top-12 w-full bg-white max-h-80 overflow-y-scroll z-30">
                        <ul>
                          {keywordOptions.map((item, index) => (
                            <li
                              key={index}
                              className="cursor-pointer w-full py-2 px-3 hover:bg-[#4D2A7C] hover:text-white"
                              onClick={() => {
                                setKeywordFunc(item);
                                setKeywordBoxOpen(false);
                                setTempKeyword("");
                                handleSearch2();
                              }}
                            >
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  {/* EXPERIENCE SEARCH  */}
                  <div
                    ref={experienceRef}
                    className="flex flex-col w-[95%] lg:w-1/3 relative"
                  >
                    <div className="experience flex flex-col w-full items-center">
                      <input
                        value={
                          exp === "Experience"
                            ? ""
                            : exp === 0
                            ? `Fresher`
                            : `${exp} ${exp === 1 ? "Year" : "Years"}`
                        }
                        type="text"
                        onClick={(e) => {
                          // setExp(e.target.value);
                          setExperienceBox(true);
                        }}
                        className="border-2 border-[#4D2A7C] w-full py-2 pr-9 pl-9 rounded-lg my-1 h-11 z-10"
                        placeholder="Experience"
                        onKeyDown={(event) =>
                          event.key === "Enter" && handleSearch2()
                        }
                      />
                      <LuCalendarCheck className="cursor-pointer text-gray-600 z-20 absolute left-3 top-1/2 -translate-y-1/2" />
                      <MdClose
                        className="cursor-pointer text-gray-600 z-20 absolute right-3 top-1/2 -translate-y-1/2"
                        onClick={() => {
                          setExp("Experience");
                          handleSearch2();
                        }}
                      />
                    </div>
                    {experienceBox && (
                      <div className="border-2 border-[#4D2A7C] absolute top-12 w-full bg-white max-h-80 overflow-y-scroll z-30">
                        <ul>
                          <li className="cursor-pointer w-full py-2 px-3 hover:bg-[#4D2A7C] hover:text-white">
                            Experience
                          </li>
                          {experienceArr.map((item, index) => (
                            <li
                              key={index}
                              className="cursor-pointer w-full py-2 px-3 hover:bg-[#4D2A7C] hover:text-white"
                              onClick={() => {
                                setExp(item);
                                setExperienceBox(false);
                                setKeywordBoxOpen(false);
                              }}
                            >
                              {item === 0
                                ? "Fresher"
                                : `${item} ${item === 1 ? "Year" : "Years"}`}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  {/* LOCATION SEARCH  */}
                  <div
                    ref={locationRef}
                    className="flex flex-col w-[95%] lg:w-1/3 relative"
                  >
                    <div className="Location flex flex-col w-full items-center">
                      <input
                        value={locationKeyword}
                        type="text"
                        onChange={(e) => {
                          const newLocationKeyword = e.target.value;
                          if (newLocationKeyword.length > 1) {
                            setLocationBoxOpen(true);
                          } else if (newLocationKeyword.length < 2) {
                            setLocationBoxOpen(false);
                          }
                          setLocationKeyword(newLocationKeyword);
                          handleLocationArray(
                            newLocationKeyword.split(",").pop().trim()
                          );
                        }}
                        className="border-2 border-[#4D2A7C] w-full py-2 pr-9 pl-9 rounded-lg my-1 h-11 z-10"
                        placeholder="Location"
                        onKeyDown={(event) =>
                          event.key === "Enter" && handleSearch2()
                        }
                      />
                      <IoLocationOutline className="cursor-pointer text-gray-600 z-20 absolute left-3 top-1/2 -translate-y-1/2" />
                      <MdClose
                        className="cursor-pointer text-gray-600 z-20 absolute right-3 top-1/2 -translate-y-1/2"
                        onClick={() => {
                          setLocationKeyword("");
                          setSelectedLocation([]);
                          setLocationBoxOpen(false);
                          setTimeout(() => {
                            handleSearch2();
                          }, 100);
                        }}
                      />
                    </div>
                    {locationBoxOpen && (
                      <div className="border-2 border-[#4D2A7C] absolute top-12 w-full bg-white max-h-80 overflow-y-scroll z-20">
                        <ul>
                          {locationOptions.map((item, index) => (
                            <li
                              key={index}
                              className="cursor-pointer w-full py-2 px-3 hover:bg-[#4D2A7C] hover:text-white"
                              onClick={() => {
                                setSelectedLocation((prev) => [...prev, item]);
                                setLocationFunc(item);
                                setLocationBoxOpen(false);
                              }}
                            >
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                {/* SEARCH BUTTON */}
                <div className="flex items-center p-0 my-4 md:my-1 justify-center w-full xl:w-3/12">
                  <button
                    className="mx-2 bg-[#4D2A7C] text-white py-2 px-4 rounded-lg"
                    onClick={() => handleSearch2()}
                  >
                    Search Jobs
                  </button>
                </div>
              </div>
            </div>
            {/* SEARCH END */}
            <div className="total_jobs_wrappe">
              <div className="total_jobs ">
                Displaying {data.length} of {fixedData.length} jobs
              </div>
            </div>
            <div className="opening_card_wrapper">
              {data.length !== 0 ? (
                data.map((items, index) => {
                  return (
                    <div className="opening_card" key={items?._id}>
                      <OpeningsBox
                        data={items}
                        showItems={showIndex === index ? true : false}
                        setShowIndex={() => setShowIndex(index)}
                        setHideIndex={() => setShowIndex(null)}
                        getJobs={getJobs}
                        getArchivedJobs={getArchivedJobs}
                        route="admin"
                        setActiveTab={setActiveTab}
                        setCurrJobId={setCurrJobId}
                        setEditType={setEditType}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="header">
                  {/* <div className="main_heading" style={{ fontSize: "2rem" }}>
                <span className="first_alpha">N</span>o Job Found
              </div> */}
                  <div className="total_jobs">
                    Sorry, no matching jobs found. <br /> Please{" "}
                    <span className="career-pragati open_application">
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdAncV9AYgKwG5JRO5C5WR0kPp9jzEhabqMxnYalbAsX836fg/viewform"
                        target="_blank"
                        rel="noreferrer"
                      >
                        submit your profile
                      </a>
                    </span>{" "}
                    for future openings that match your skills and interests.
                    {/* <span className="career-pragati">career pragati</span> services. */}
                  </div>
                </div>
              )}
            </div>
            {/* {data.length !== 0 && (
          <div className="header">
            <div className="total_jobs flex">
              <div className="image_container">
                <img src={p2} alt="apply" />
              </div>
              <div>
                Looking for a job that better matches your skills and location?
                <br />
                Please submit your profile for{" "}
                <span className="career-pragati open_application">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdAncV9AYgKwG5JRO5C5WR0kPp9jzEhabqMxnYalbAsX836fg/viewform"
                    target="_blank"
                    rel="noreferrer"
                  >
                    future openings here
                  </a>
                </span>
                .
              </div>
            </div>
          </div>
        )} */}
          </div>
        </div>
      )}

      {/* ------------------------------ Archived Jobs ----------------------------- */}
      {activeTab === "Archived Jobs" && (
        <div className="curr_openings_wrapper" style={{ overflowX: "hidden" }}>
          <div className="flex justify-end w-full">
            <CsvDownloadButton
              className={`options active`}
              data={fixedArchived}
              filename="Archived Jobs"
            />
          </div>

          <div className="curr_openings_body">
            <div className="total_jobs_wrappe">
              <div className="total_jobs ">
                Displaying {archived.length} of {fixedArchived.length} jobs
              </div>
            </div>

            <div className="opening_card_wrapper">
              {archived.length !== 0 ? (
                archived.map((items, index) => {
                  return (
                    <div className="opening_card" key={items?._id}>
                      <OpeningsBox
                        data={items}
                        showItems={showIndex === index ? true : false}
                        setShowIndex={() => setShowIndex(index)}
                        setHideIndex={() => setShowIndex(null)}
                        getArchivedJobs={getArchivedJobs}
                        getJobs={getJobs}
                        route="archived"
                        setCurrJobId={setCurrJobId}
                        setActiveTab={setActiveTab}
                        setEditType={setEditType}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="header">
                  <div className="total_jobs">No Jobs Archived</div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* ----------------------------- Upload Jobs ------------------------------ */}
      {activeTab === "Upload Job" && (
        <UploadJob
          currJobId={currJobId}
          getJobs={getJobs}
          getArchivedJobs={getArchivedJobs}
          editType={editType}
          disciplines={disciplines}
          industries={industries}
          locations={locations}
          setActiveTab={setActiveTab}
        />
      )}
    </div>
  );
};

export default AdminPanel;
