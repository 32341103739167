import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loggedin: false,
}

export const userSlice = createSlice({
    name:"user",
    initialState,
    reducers:{
        userClick: (state, action)=>{
            state.loggedin = action.payload;
        },
    }
})

export const {userClick} = userSlice.actions;

export default userSlice.reducer