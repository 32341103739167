import React from "react";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
const CurrOpenFooter = ({ data, showItems, setShowIndex2, setHideIndex }) => {
  return (
    <div className="faqs">
      <div className="faq_body">
        <div
          className="upper_box"
          onClick={() => {
            showItems ? setHideIndex() : setShowIndex2();
          }}
        >
          <div style={{ width: "22rem" }}>{data?.question}</div>
          {showItems ? (
            <FaChevronUp className="arrow" />
          ) : (
            <FaChevronDown className="arrow" />
          )}
        </div>
        {showItems && <div className="down_box">{data?.ans}</div>}
      </div>
    </div>
  );
};

export default CurrOpenFooter;
