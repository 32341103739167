import React, { useEffect, useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import { IoMdArchive } from "react-icons/io";
import { ImFolderUpload } from "react-icons/im";
import { FaEdit } from "react-icons/fa";
import { GrClone } from "react-icons/gr";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { BsCalendar2CheckFill } from "react-icons/bs";
import { serverUrl } from "../BackendUrl";
import { Toaster, toast } from "react-hot-toast";

// This component renders individual job openings
function OpeningsBox({
  data,
  showItems,
  setShowIndex,
  setHideIndex,
  getJobs,
  route,
  getArchivedJobs,
  setActiveTab,
  setCurrJobId,
  setEditType,
}) {
  const navigate = useNavigate();
  const [priorityclass, setPriorityClass] = useState("");

  // Function to handle archiving a job
  const handleDelete = async () => {
    try {
      const res = await fetch(`${serverUrl}/job/delete-job/${data._id}`, {
        method: "POST",
      });
      const json = await res.json();
      if (json === 200) {
        getJobs();
        getArchivedJobs();
        toast.success("Job Archived");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle restoring a job
  const handleRestore = async () => {
    try {
      const res = await fetch(`${serverUrl}/job/restore-job/${data._id}`, {
        method: "POST",
      });
      const json = await res.json();
      if (json === 200) {
        getJobs();
        getArchivedJobs();
        toast.success("Job Restored");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle editing a job
  const handleEdit = async () => {
    setCurrJobId(data._id);
    setActiveTab("Upload Job");
    setEditType("Edit");
  };

  // Function to handle cloning a job
  const handleClone = async () => {
    setCurrJobId(data._id);
    setActiveTab("Upload Job");
    setEditType("Clone");
  };

  // Function to handle navigation to job details page
  const handleNavigate = () => {
    const title = data.title.split("/").join("");
    const finalTitle = title.split(" ").join("");
    const location = data.location.split("/").join("");
    const finalLocation = location.split(" ").join("");
    const industry = data.industry.split("/").join("");
    const finalIndustry = industry.split(" ").join("");
    const category = data.discipline.split("/").join("");
    const finalCategory = category.split(" ").join("");
    const url = `/jobs/${finalTitle}/${finalLocation}/${data._id}`;
    navigate(`/jobs/${finalTitle}/${finalLocation}/${data._id}`);
  };

  // Set the priority class based on job priority
  useEffect(() => {
    if (data?.priority === "True") {
      setPriorityClass("upper_box_imp");
    }
  }, []);

  return (
    <>
      {/* Render the job opening */}
      <div className={`openingBox_wrapper  ${priorityclass}`}>
        <Link
          className={`upper_box ${priorityclass}`}
          onClick={() => handleNavigate()}
          style={{ textDecoration: "none" }}
        >
          <div className={`upper_box_div `}>
            {data?.title ? data?.title : data?.industry}
            {route === "archived" || route === "admin" ? (
              <span>
                <b>({`${data?.jobid}`})</b>
              </span>
            ) : (
              <span></span>
            )}
          </div>
          <div className="loc_exp">
            <div className="upper_box_div2">
              <div className={`location_sub`}>
                <p>
                  <FaLocationDot />
                </p>
                <p>{data?.location}</p>
              </div>
            </div>
            <div className="upper_box_div3">
              <div className={`exp_sub`}>
                <p>
                  <BsCalendar2CheckFill />
                </p>
                <p>
                  {data?.minExp} - {data?.maxExp} Years
                </p>
              </div>
            </div>
          </div>
        </Link>
        {/* Render admin buttons */}
        {route === "admin" && (
          <div className="box-btn-wrapper">
            <div className="delete-btn" onClick={() => handleDelete()}>
              <IoMdArchive />
              <span>Archive</span>
            </div>
            <div className="delete-btn" onClick={() => handleEdit()}>
              <FaEdit />
              <span>Edit</span>
            </div>
            <div className="delete-btn" onClick={() => handleClone()}>
              <GrClone />
              <span>Clone</span>
            </div>
          </div>
        )}
        {/* Render archived buttons */}
        {route === "archived" && (
          <div className="box-btn-wrapper">
            <div className="delete-btn" onClick={() => handleRestore()}>
              <ImFolderUpload />
              <span>Restore</span>
            </div>
            <div className="delete-btn" onClick={() => handleEdit()}>
              <FaEdit />
              <span>Edit</span>
            </div>
            <div className="delete-btn" onClick={() => handleClone()}>
              <GrClone />
              <span>Clone</span>
            </div>
          </div>
        )}
      </div>
      {/* Render toast notifications */}
      <Toaster position="top-right" />
    </>
  );
}

export default OpeningsBox;