import React, { useEffect, useState, useRef } from 'react'
import { IoMdClose } from "react-icons/io";
import { Navigate } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
import { Toaster, toast } from 'react-hot-toast';  
import { serverUrl } from './BackendUrl';

const UploadJob = (props) => {
    const jid = props.currJobId;
    const [jobId, setJobId] = useState(0);
    const [jobTitle, setJobTitle] = useState("");
    const [discipline, setDiscipline] = useState("");
    const [industry, setIndustry] = useState("");
    const [location, setLocation] = useState("");
    const [minExp, setMinExp] = useState("");
    const [maxExp, setMaxExp] = useState("");
    const [what, setWhat] = useState("");
    const [who, setWho] = useState("");
    const [tools, setTools] = useState("");
    const [employer, setEmployer] = useState("");
    const [roleType, setRoleType] = useState("");
    const [priority, setPriority] = useState("False");
    const [showDiscipline, setShowDiscipline] = useState(false);
    const [showIndustry, setShowIndustry] = useState(false);
    const [showLocation, setShowLocation] = useState(false);
    const route = jid ? "edit": "upload";
    const dropdownRef = useRef(null);
    const dropdownRefIndustry = useRef(null);
    const dropdownRefDiscipline = useRef(null);

    const uploadJob = async () => {
        try {
            const response = await fetch(`${serverUrl}/job/create-job`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    jobid: jobId,
                    title: jobTitle,
                    discipline: discipline,
                    industry: industry,
                    location: location,
                    minExp: minExp,
                    maxExp: maxExp,
                    what: what,
                    who: who,
                    tools: tools,
                    employer: employer,
                    roleType: roleType,
                    priority: priority
                }),
            })
            if(response.status === 201) {
                toast("Job uploaded successfully");
                setJobId("");
                setJobTitle("");
                setDiscipline("");
                setIndustry("");
                setLocation("");
                setMinExp("");
                setMaxExp("");
                setWhat("");
                setWho("");
                setTools("");
                setEmployer("");
                setRoleType("");
                props.getJobs();
                props.getArchivedJobs();
                props.setActiveTab("Live Jobs");
            }else{
                alert("Something went wrong");
            }
        } catch (error) {
            console.log(error)
        }
    } 
 
    useEffect(() => {
      if(jid !== null){
        fetchJob(); 
      }
      if(route === "upload"){
        setEmployer("Product-based OEM/MNC. Employer name and detailed job description are confidential for now. Once your profile is shortlisted, all details will be shared with you directly")
        setRoleType("Payroll of Employer {Direct employment}. 5 days work-from-office")
      }
    }, [])
    // console.log(priority)  
    const ClearData = () => {
        setJobId("");
        setJobTitle("");
        setDiscipline("");
        setIndustry("");
        setLocation("");
        setMinExp("");
        setMaxExp("");
        setWhat("");
        setWho("");
        setTools("");
        setEmployer("");
        setRoleType("");
    }

    const updateJob = async() => {
      try {
        const response = await fetch(`${serverUrl}/job/update-job/${jid}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                jobid: jobId,
                title: jobTitle,
                discipline: discipline,
                industry: industry,
                location: location,
                minExp: minExp,
                maxExp: maxExp,
                what: what,
                who: who,
                tools: tools,
                employer: employer,
                roleType: roleType,
                priority: priority
            }),
        })
        if(response.status === 201) {
            toast("Job updated successfully");
            setJobId("");
            setJobTitle("");
            setDiscipline("");
            setIndustry("");
            setLocation("");
            setMinExp("");
            setMaxExp("");
            setWhat("");
            setWho("");
            setTools("");
            setEmployer("");
            setRoleType("");
            props.getJobs();
            props.getArchivedJobs();
            props.setActiveTab("Live Jobs");
            
        }else{
            alert("Something went wrong");
        }
    } catch (error) {
        console.log(error)
    }
    }
    const fetchJob = async () => {
      try {
        const response = await fetch(`${serverUrl}/job/get-single-job/${jid}`);
        const res = await response.json();
        setJobId(res.jobid);
        setJobTitle(res.title);
        setDiscipline(res.discipline);
        setIndustry(res.industry);
        setLocation(res.location);
        setMinExp(res.minExp);
        setMaxExp(res.maxExp);
        setWhat(res.what);
        setWho(res.who);
        setTools(res.tools);
        setEmployer(res.employer);
        setRoleType(res.roleType);
        setPriority(res.priority);
        if(props.editType === "Clone"){
          const newId = res.jobid + 1;
          const newTitle = res.title + " (Copy)";
          setJobId(newId);
          setJobTitle(newTitle);
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowLocation(false);
      }
    };
    const handleClickOutsideIndustry = (event) => {
      if (dropdownRefIndustry.current && !dropdownRefIndustry.current.contains(event.target)) {
        setShowIndustry(false);
       
      }
    };
    const handleClickOutsideDiscipline = (event) => {
      if (dropdownRefDiscipline.current && !dropdownRefDiscipline.current.contains(event.target)) {
        setShowDiscipline(false);
      }
    };

    useEffect(() => {
      // Add event listener on document mount
      document.addEventListener('click', handleClickOutside);
      document.addEventListener('click', handleClickOutsideIndustry);
      document.addEventListener('click', handleClickOutsideDiscipline);
  
      // Cleanup function to remove event listener on unmount
      return () => document.removeEventListener('click', handleClickOutside);
    }, []);
    // console.log(priority)

  return (
    <div className='upload-wrapper text-black'>
      <h1 className='title '>Fill the job details</h1>
      <div className='form-group'>
        <label className='form-label'>Job ID</label>
        <input type='number' className='form-control  text-black' value={jobId}  onChange={(e) => setJobId(e.target.value)} required/>
      </div>
      <div className='form-group'>
        <label className='form-label'>Job Title</label>
        <input type='text' className='form-control  text-black' value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} required/>
      </div>
      <div ref={dropdownRefDiscipline} className='form-group'> 
        <label className='form-label'>Category</label>
        <input type='text' className='form-control  text-black' value={discipline} onClick={() => setShowDiscipline(true)} onChange={(e) => {setDiscipline(e.target.value);}} required/>
        {showDiscipline && 
        <div className='dropdown'>
          <button onClick={() => setShowDiscipline(false)}><IoMdClose /></button>
          {props.disciplines.map((discipline) => (
            <p  onClick={() => {setDiscipline(discipline); setShowDiscipline(false)}}>{discipline}</p>
          ))}
        </div>}
      </div>
      <div ref={dropdownRefIndustry} className='form-group'>
        <label className='form-label'>Industry</label>
        <input type='text' className='form-control  text-black' value={industry} onClick={() => setShowIndustry(true)} onChange={(e) => setIndustry(e.target.value)} required/>
        {showIndustry &&
        <div className='dropdown'>
          <button onClick={() => setShowIndustry(false)}><IoMdClose /></button>
          {props.industries.map((industry) => (
            <p  onClick={() => {setIndustry(industry); setShowIndustry(false)}}>{industry}</p>
          ))}
        </div>}
      </div>
      <div  ref={dropdownRef}  className='form-group'>
        <label className='form-label'>Location</label>
        <input type='text' className='form-control  text-black' value={location} onClick={() => setShowLocation(true)} onChange={(e) => setLocation(e.target.value)} required/>
        {showLocation &&
        <div className='dropdown'>
          <button onClick={() => setShowLocation(false)}><IoMdClose /></button>
          {props.locations.map((location) => (
            <p onClick={() => {setLocation(location); setShowLocation(false)}}>{location}</p>
          ))}
        </div>}
      </div>
      <div className='form-group'>
        <label className='form-label'>Minimum Experience</label>
        <input type='number' className='form-control  text-black' value={minExp} onChange={(e) => setMinExp(e.target.value)} required/>
      </div>
      <div className='form-group'>
        <label className='form-label'>Maximum Experience</label>
        <input type='number' className='form-control  text-black' value={maxExp} onChange={(e) => setMaxExp(e.target.value)} required/>
      </div>
      <div className='form-group'>
        <label className='form-label'>What the role involves </label>
        <textarea type='text' className='form-control  text-black' value={what} onChange={(e) => setWhat(e.target.value)} required/>
        <p>{`Character Count: ${what.length}`}</p>
      </div>
      <div className='form-group'>
        <label className='form-label'>Who is an ideal candidate</label> 
        <textarea type='text' className='form-control  text-black' value={who} onChange={(e) => setWho(e.target.value)} required/>
        <p>{`Character Count: ${who.length}`}</p>
      </div>
      <div className='form-group'>
        <label className='form-label'>WHICH (Skills/Tools are needed):</label>
        <textarea type='text' className='form-control  text-black' value={tools} onChange={(e) => setTools(e.target.value)} required/>
        <p>{`Character Count: ${tools.length}`}</p>
      </div>
      <div className='form-group'>
        <label className='form-label'>Employer</label>
        <textarea type='text' className='form-control  text-black' value={employer} onChange={(e) => setEmployer(e.target.value)} required/>
        <p>{`Character Count: ${employer.length}`}</p>
      </div>
      <div className='form-group'>
        <label className='form-label'>Role Type</label>
        <textarea type='text' className='form-control  text-black' value={roleType} onChange={(e) => setRoleType(e.target.value)} required/>
        <p>{`Character Count: ${roleType.length}`}</p>
      </div>
      <div className='form-group'>
        <label className='form-label'>Priority</label>
        <select className='form-control  text-black' onChange={(e) => setPriority(e.target.value)} value={priority} required>
          <option>False</option>
          <option>True</option>  
        </select>
      </div>
      <div className='btn-group text-black'>
        {route === "edit" &&
        <>
          {props.editType === "Edit" && <button className='upload-btn text-black' onClick={()=>updateJob()}>Save Changes</button>}
          {props.editType === "Clone" && <button className='upload-btn text-black' onClick={()=>uploadJob()}>Upload</button>}
        </>
        }
        {route === "upload" && <button className='upload-btn text-black' onClick={()=>uploadJob()}>Upload Job</button>}
        <button className='upload-btn text-black' onClick={()=>ClearData()}>Clear</button>
      </div>
      <Toaster position="top-right" />
    </div>
  )
}

export default UploadJob
