import React, { useEffect, useState } from 'react'
import {useNavigate} from 'react-router-dom';
import {serverUrl} from "./BackendUrl"

const ChangePassword = () => {

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false); 
    const [admin, setAdmin] = useState([]);
    const [remainingTime, setRemainingTime] = useState(59);
    const navigate = useNavigate();

    const getAdmin = async () => {
      try {
        const response = await fetch(`${serverUrl}/admin/get-admin`, {
          method: "GET",
        })
        const res = await response.json();
        setAdmin(res);
      } catch (error) {
        console.log(error)
      }
    }
    useEffect(() => {
      getAdmin();
    }, [])

    const getOtp = async (event) => {
      (event && event.preventDefault())
      if (!isOtpSent) try {
        const response = await fetch(`${serverUrl}/admin/send-otp`, {
          method: "GET",
          headers: {
            "Content-Type": "text/html",
          },
        })
        const res = await response.json();
        alert(res.message)
        setIsOtpSent(true);
      } catch (error) {
        console.log(error)
      }
    }

    useEffect(() => {
      let intervalId;
      if (isOtpSent) {
        intervalId = setInterval(() => {
          if (remainingTime > 0) {
            setRemainingTime(remainingTime - 1);
          } else {
            setIsOtpSent(false); // Reset OTP sent state and timer after 59 seconds
            setRemainingTime(59);
          }
        }, 1000); // Update timer every second
      }
      return () => clearInterval(intervalId); // Clear interval on component unmount
    }, [isOtpSent, remainingTime]);

    const saveAdmin = async () => {
      if(otp === ''){
        alert('Please enter OTP');
      }
      if(password !== confirmPassword){
        alert('Passwords do not match');
      }
      try {
        const response = await fetch(`${serverUrl}/admin/edit-admin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            _id: admin[0]._id,
            username: userName,
            password: password,
            otpBody: otp
          }),
        })
        const res = await response.json();
        alert(res.message)
        if(res){
          navigate("/admin_login55300")
          setUserName('');
          setPassword('');
          setConfirmPassword('');
          setOtp('');
          setIsOtpSent(false);
          setRemainingTime(59);
        }
      } catch (error) {
        console.log(error) 
      }
    }

  return (
    <div className="admin_login_wrapper">
        <div className="admin_login">
          <div className="admin_wrapper">
            <h2 className="admin_title">Change credentials</h2>
            <div className="input_box">
              <div className="input_wrapper">
                <label htmlFor="username" className="input_label">
                  Enter UserName
                </label>
                <input
                  type="text"
                  id="username"
                  className="input_field"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="Enter Username"  
                />
              </div>
              <div className="input_wrapper">
                <label htmlFor="newPass" className="input_label">
                  Enter New Password
                </label>
                <input
                  type="password"
                  id="newPass"
                  className="input_field"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter New Password"  
                />
              </div>
              <div className="input_wrapper">
                <label htmlFor="confirmpassword" className="input_label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="confirmpassword"
                  className="input_field"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                />
              </div>
              <div className="input_wrapper">
                <div className="otp_wrapper">
                <label htmlFor="otp" className="input_label">
                  Enter OTP
                </label>
                <button
                className={` ${isOtpSent ? 'disabled' : ''}`}
                disabled={isOtpSent}
                onClick={(event) => getOtp(event)}
              >
                Get OTP
              </button>
                </div>
                <input
                  type="number"
                  id="otp"
                  className="input_field"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Enter OTP"
                  />
                {isOtpSent && <div className="otp-mssg">Time left: {remainingTime} secs</div>}
              </div>
              <div className="admin_submit">
                <button className="login_btn" onClick={() => saveAdmin()}>
                  Save
                </button>
                </div>
            </div>
          </div>
        </div>
      
    </div>
  )
}

export default ChangePassword


