import React from "react";
import { RxCross2 } from "react-icons/rx";
import { useState } from "react";

import img1 from "../images/talent1.jpg";
import img2 from "../images/talent2.jpg";
import img3 from "../images/talent3-1.jpg";
import img5 from "../images/talent4.jpg";
import test1 from "../images/test1.jpg";
import test2 from "../images/test2.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const NurtureTalent = () => {
  const [showCards, setShowCards] = useState(Array(10).fill(false));

  const handleCrossClick = (cardIndex) => {
    const updatedShowCards = [...showCards];
    updatedShowCards[cardIndex] = false;
    setShowCards(updatedShowCards);
  };

  const handleClick = (cardIndex) => {
    const updatedShowCards = [...showCards];
    updatedShowCards[cardIndex] = true;
    setShowCards(updatedShowCards);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scrolling smooth
    });
  };
  return (
    <div className="talent_wrapper">
      <Helmet>
        <meta
          name="description"
          content="Elevate your team's competency with Pragati People. We provide tailored training programs, workshops, and continuous development opportunities to nurture talent and drive organizational success. "
        />
        <meta
          name="keywords"
          content="training programs, skill enhancement, professional development, workshops, continuous development, talent nurturing, organizational success, Corporate training, L&D, T&D, Leadership training, Soft skills training"
        />
      </Helmet>
      <div className="header">
        <h1>Nurture Talent</h1>
        <div className="main_heading">
          <span className="first_alpha">N</span>urturing = Training + Traction
        </div>
        <div>
          <span className="first_alpha">O</span>ur approach of Nurturing talent
          is grounded in the principles of 70-20-10 model.
        </div>
        <div>
          We drive training sessions with one primary goal sharpen and
          strengthen the participant’s job-related skills by hands-on
          experiential learning.
        </div>
      </div>
      <div className="talent_cards_wrapper" style={{ marginTop: "2rem" }}>
        <h1
          className="talent_heading main_heading"
          style={{ textAlign: "center", fontSize: "2rem" }}
        >
          Our offerings for “Technical Trainings”{" "}
        </h1>
        <div className="our_services home_services border">
          <div className="who">
            <img src={img1} alt="" />
            <div className="upper_layer">
              <h2>Product Development</h2>
              <button className="btn_primary" onClick={() => handleClick(0)}>
                Read More
              </button>
            </div>

            <div
              className={`inner_layer ${showCards[0] ? "visible" : "hidden"}`}
            >
              <ul>
                <RxCross2
                  className="cross_logo_talent"
                  onClick={() => handleCrossClick(0)}
                />
                <li>CAD</li>
                <li>FEA</li>
                <li>VAVE</li>
              </ul>
            </div>
          </div>
          <div className="what">
            <img src={img2} alt="" />
            <div className="upper_layer">
              <h2>Methods</h2>
              <button className="btn_primary" onClick={() => handleClick(1)}>
                Read More
              </button>
            </div>
            <div
              className={`inner_layer ${showCards[1] ? "visible" : "hidden"}`}
            >
              <ul>
                <RxCross2
                  className="cross_logo_talent"
                  onClick={() => handleCrossClick(1)}
                />
                <li>Six Sigma</li>
                <li>Scrum</li>
                <li>Project Management</li>
              </ul>
            </div>
          </div>
        </div>
        <h1
          className="talent_heading main_heading"
          style={{ textAlign: "center", fontSize: "2rem" }}
        >
          Our offerings for “Interpersonal Trainings”
        </h1>
        <div className="our_services home_services border">
          <div className="who">
            <img src={img3} alt="" />
            <div className="upper_layer">
              <h2>Soft Skills </h2>
              <button className="btn_primary" onClick={() => handleClick(2)}>
                Read More
              </button>
            </div>

            <div
              className={`inner_layer ${showCards[2] ? "visible" : "hidden"}`}
            >
              <ul>
                <RxCross2
                  className="cross_logo_talent"
                  onClick={() => handleCrossClick(2)}
                />
                <li>Communication</li>
                <li>Time Management</li>
                <li>Campus to Corporate</li>
              </ul>
            </div>
          </div>
          <div className="what">
            <img src={img5} alt="" />
            <div className="upper_layer">
              <h2>Leadership</h2>
              <button className="btn_primary" onClick={() => handleClick(3)}>
                Read More
              </button>
            </div>
            <div
              className={`inner_layer ${showCards[3] ? "visible" : "hidden"}`}
            >
              <ul>
                <RxCross2
                  className="cross_logo_talent"
                  onClick={() => handleCrossClick(3)}
                />
                <li>Mentor-Manager</li>
                <li>First Time Leaders</li>
                <li>Executive Coaching</li>
              </ul>
            </div>
          </div>
        </div>

        <h1
          className="talent_heading main_heading"
          style={{ textAlign: "center", fontSize: "2rem" }}
        >
          Feedback received on our trainings
        </h1>
        <div className="our_services home_services">
          <div className="noscale">
            <img src={test1} alt="" className="test" />
          </div>
          <div className="noscale">
            <img src={test2} alt="" className="test" />
          </div>
        </div>
      </div>
      <div className="home_data">
        <div className="message">
          Due to employment or contractual obligations, many of our customers
          have requested anonymity.
        </div>
        <div className="message">
          Here are some{" "}
          <Link to={"/testimonials"} onClick={scrollToTop}>
            customer-testimonials
          </Link>{" "}
          with their full names and LinkedIn profiles.
        </div>
      </div>
    </div>
  );
};

export default NurtureTalent;
