import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userClick } from "../Slices/userSlice";
import { serverUrl } from "./BackendUrl";
import { Toaster, toast } from "react-hot-toast";

// This component handles the login functionality for the admin users
const AdminLogin = () => {
  // State variables to store the username, password, error message and the state of the checkbox
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState("password");
  const [checked, setChecked] = useState(false);

  // Function to handle the login functionality
  const handleSubmit = async () => {
    try {
      // Make a POST request to the server to authenticate the admin user
      const response = await fetch(`${serverUrl}/admin/login-admin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: userName,
          password: password,
        }),
      })
      // If the login is successful, dispatch an action to update the user state and navigate to the user panel
      if (response.status === 201) {
        setError("");
        dispatch(userClick(true));
        toast("Login Successful");
        navigate("/user-name-panel");
      } else {
        // If the login fails, display an error message for 1 second
        setError("Invalid username or password");
        setTimeout(() => {
          setError("");
        }, 1000);
      }
    } catch (error) {
      console.log(error)
    }
  }

  // Function to toggle the visibility of the password
  const showPassword = () => {
    // If the checkbox is unchecked, change the state of the checkbox and the type of the password input to text
    if(checked === false){
      setShowPass("text")
    }
    // If the checkbox is checked, change the state of the checkbox and the type of the password input to password
    if(checked === true){
      setShowPass("password")
    }
  };
 
  return (
    // Main div to wrap the entire component
    <div className="admin_login_wrapper">
        <div className="admin_login">
          <div className="admin_wrapper">
            <h2 className="admin_title">Admin Login</h2>
            <h3 className="admin_subtitle">
              Make sure you have the right credentials
            </h3>
            <div className="input_box">
              <div className="input_wrapper">
                <label htmlFor="username" className="input_label">
                  Enter Username
                </label>
                <input
                  type="text"
                  id="username"
                  className="input_field"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="Enter Username"  
                  autoComplete="on"
                />
              </div>
              <div className="input_wrapper">
                <label htmlFor="password" className="input_label">
                  Enter Password
                </label>
                <input
                  type={showPass}
                  id="password"
                  className="input_field"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter Password"
                  autoComplete="on"
                  onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
                />
                <div className="show_password" onClick={()=> showPassword()}>
                  {/* Checkbox to toggle the visibility of the password */}
                  <input type="checkbox" id="showPassword" checked={checked} onClick={()=> setChecked(!checked)} onChange={()=>showPassword()} />
                  <label onClick={()=> setChecked(!checked)}>
                    Show Password
                  </label>
                </div>
              </div>
              <div className="admin_submit">
                {/* Button to trigger the login functionality */}
                <button className="login_btn" onClick={handleSubmit}>
                  Login
                </button>
                {/* Div to display any error messages */}
                <div className="error">{error}</div>
                {/* Div to navigate to the forgot password page */}
                <div className="mssg" role="button" onClick={() => navigate("/forgotpassword")}><u>Forgot Password?</u></div>
              </div>
            </div>
          </div>
        </div>
      {/* Toaster component to display toast messages */}
      <Toaster position="top-center"/>
    </div>
  );
};

export default AdminLogin;

