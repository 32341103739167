// Icons
// import { FaLinkedinIn } from "react-icons/fa6";
// import { IoMdMail } from "react-icons/io";
import { Link } from "react-router-dom";
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scrolling smooth
    });
  };
  return (
    <div className="footer_wrapper">
      <Link to={"/contact"} style={{ textDecoration: "none" }} onClick={scrollToTop}>
        <div className="contact_us">Contact Us</div>
      </Link>
      {/* <div className="logos">
        <div className="linkedin" style={{ background: "#4D2A7C" }}>
          <a
            href="https://www.linkedin.com/in/sanjeev-nichani-684358a/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedinIn style={{ color: "white" }} />
          </a>
        </div>
        <div
          className="linkedin"
          style={{
            background: "#4D2A7C",
          }}
        >
          <a
            href="mailto:open.pragati@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <IoMdMail
              style={{
                color: "white",
                position: "absolute",
                left: "19%",
                top: "22%",
              }}
            />
          </a>
        </div>
      </div> */}
    </div>
  );
};
export default Footer;
